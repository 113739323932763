<template>
  <footer>
    <div class="bottom-section">
      <div class="row find-us-section bcolor-secondary">
        <div class="col">
          <h2 class="font-1 color-primary">{{ findUsText }}</h2>
          <div class="social-media-logos-bar">
            <a
              target="_blank"
              class="social-media-logo-link"
              v-for="socialMedia in socialMedias"
              :href="socialMedia.link"
              :key="socialMedia.link"
            >
              <img
                class="social-media-logo"
                :src="require(`../../assets/img/${socialMedia.path}`)"
              />
            </a>
          </div>
          <div class="color-5 font-3 lower-contact">
            <a class="color-5" href="tel:+34-934-57-47-84">+34934574784</a> .
            <a
              class="color-5"
              href="https://www.google.com/maps/place/Tomodachi+-+Restaurant+Japon%C3%A8s/@41.4054657,2.1646083,15z/data=!3m1!5s0x12a4a2bf57a6342d:0x874a6f94d85bff28!4m12!1m6!3m5!1s0x0:0xf921b20935ac0ec4!2sTomodachi+-+Restaurant+Japon%C3%A8s!8m2!3d41.4054657!4d2.1646083!3m4!1s0x0:0xf921b20935ac0ec4!8m2!3d41.4054657!4d2.1646083"
              >Travessera de Gràcia, 250, 08025 Barcelona .   </a>
            <a class="color-5" href="mailto:tomodachibcn@gmail.com"
              >tomodachibcn@gmail.com
            </a>
          </div>
        </div>
      </div>
      <div class="row copyright-section">
        <div class="col">
          <p>
            <span class="font-2 color-primary copyright-name">
              (C) Tomodachi BCN .
            </span>
            <span class="font-3 color-5 copyright-text">
              {{ copyrightText }}.</span
            >
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function() {
    return {
      socialMedias: [
        {
          link: "https://www.facebook.com/tomodachibcnsushi",
          path: "social-media-logos/facebook_64.png",
        },
        {
          link: "https://www.instagram.com/tomodachibcn/",
          path: "social-media-logos/instagram_64.png",
        }
      ],
    };
  },
  computed: {
    copyrightText: function() {
      return this.getFooterData().copyrightText;
    },
    findUsText: function() {
      return this.getFooterData().findUs;
    },
  },
  methods: {
    getFooterData: function() {
      return this.$store.getters.currentData.footer;
    },
  },
};
</script>

<style>
.copyright-section {
  font-size: 14px;
}

.find-us-section {
  padding-top: 30px;
  padding-bottom: 30px;
}

footer {
  text-align: center;
}

.copyright-section {
  padding-top: 18px;
  background-color: #383838;
}

.copyright-name {
  font-size: 18px;
}

.copyright-text {
  letter-spacing: 1px;
  font-size: 15px;
}

.social-media-logos-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.social-media-logo {
  height: 32px;
  width: 32px;
}

.social-media-logo-link {
  margin-left: 10px;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  transition: transform 0.25s ease;
}

.social-media-logo-link:hover {
  transform: translateY(-11px) scale(1.05);
}

.lower-contact {
  font-size: 13px;
  padding-right: 10px;
  padding-left: 10px;
}
</style>
