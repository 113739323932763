<template>
  <div id="link-menu-section" class="container menu-card">
    <p class="menu-card__title color-4">La Carta</p>
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
    >
      Menú en otro idiomas
    </button>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Menú en otro idiomas
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body menu-link">
            <a class="color-4" target="_blank" title="Menu in catalan" href="https://www.tomodachibcn.es/menu/carta-ca.png"
              >Menu Catalan</a
            >
            <a class="color-4" target="_blank" title="Menu in English" href="https://www.tomodachibcn.es/menu/carta-eng.png"
              >Menu English</a
            >
          </div>
        </div>
      </div>
    </div>
    <img
      class="image-section"
      :src="require(`../../assets/img/carta-es.png`)"
      alt="tomodachi sushi resturant menu image"
    />
  </div>
</template>

<script>
export default {
  name: "MenuCard",
};
</script>

<style lang="scss">
.menu-card {
  padding: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;

  @media only screen and (max-width: 800px) {
    font-size: 35px;
  }

  &__title {
    font-size: 45px;
    font-family: "Uchen", serif;
  }
  button {
    border: 1px solid #91974b;
    border-radius: 15px;
    font-size: 20px;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }
}

.image-section {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .menu-card {
    margin-top: 2rem;

    &__title {
      font-size: 35px;
    }
  }
}

.menu-link {
  display: flex;
  justify-content: space-evenly;
  a {
    padding: 0.5rem;
    border-radius: 15px;
    border: 1px solid #91974b;
    font-weight: bold;
    transition: opacity 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-size: 15px;

    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }
}
</style>
