<template>
  <div class="parallax">
    <div class="darker-overlay">
      <div class="extra-info">
        <p class="font-4 color-3">
          {{ getScheduleData.title }}
        </p>
        <button
          type="button"
          class="btn btn-primary extra-info__btn"
          data-bs-toggle="modal"
          data-bs-target="#reservationModal"
        >
          <i class="fas fa-book"></i>
         {{ getScheduleData.ctaText }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Parallex",
  computed: {
    getScheduleData: function () {
      return this.$store.getters.currentData.extraInfo;
    }
  }
};
</script>
<style lang="scss">
.parallax {
  /* The image used */
  background-image: url("../../assets/img/hall.jpg");

  /* Set a specific height */
  min-height: 500px;
  position: relative;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.darker-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 250px;
  padding-bottom: 250px;
}
.extra-info {
  font-size: 1.5rem;
  text-align: left;
  position: absolute;
  top: 100px;
  left: 50px;
  text-shadow: 1px 1px 2px #a42e0e;
  font-weight: bolder;

  @media only screen and (min-width: 800px) {
    font-size: 2rem;
    top: 150px;
    left: 100px;
    width: 60%;
  }
  &__btn {
    background-color: #0b658f !important;
    margin-top: 1rem;
  }
}
</style>

