<template>
  <Header />
  <main>
    <HeroImage />
    <GeneralInfo />
    <Gallery />
    <OurSchedule />
    <ContactUs />
    <Location />
    <MenuCard />
    <Parallex />
  </main>
  <Footer />
</template>

<script>
// this is the overall components, all subcomponents must be included here
import Header from "./Header";
import HeroImage from "./HeroImage";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import Location from "./Location";
import GeneralInfo from "./GeneralInfo";
import OurSchedule from "./OurSchedule";
import MenuCard from "./MenuCard";
import Gallery from "./Gallery";
import Parallex from "./Parallex";

export default {
  name: "Landing",
  components: {
    Header,
    HeroImage,
    Footer,
    Location,
    ContactUs,
    OurSchedule,
    GeneralInfo,
    MenuCard,
    Gallery,
    Parallex,
  },
};
</script>

<style scoped></style>
