import { createStore } from 'vuex'

import dataEn from '../data/data-en.json'
import dataEs from '../data/data-es.json'

function getData(language) {
    if (language === 'en') {
        return dataEn;
    }
    return dataEs;
}

const store = createStore({
    state : {
        currentLanguage : 'es'
    },
    getters : {
        currentLanguage : (state) =>{
            return state.currentLanguage
        },
        currentData : (state) => {
            return getData(state.currentLanguage);
        },
    },
    mutations : {
        currentLanguage (state, language) {
            state.currentLanguage = language
          },
    }
});

export default store
