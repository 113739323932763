<template>
  <div id="link-contact-us-section">
    <div class="row pt-4 pb-2">
      <div class="col">
        <p class="module-title color-4 text-center">{{ htaTitle }}</p>
        <p class="module-subtitle color-1 text-uppercase text-center">
          {{ htaSubtitle }}
        </p>
      </div>
    </div>
    <div class="container hta-container">
      <div class="row">
        <div
          v-for="contactCard in contactCards"
          :key="contactCard.icon"
          class="col-lg-4 col-md-12"
        >
          <div class="row justify-content-center h-100">
            <div class="col-lg-12 col-md-6">
              <ContactCard v-bind:contactInfo="contactCard" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactCard from "./ContactCard";

export default {
  name: "ContactUs",
  components: {
    ContactCard
  },
  methods: {
    getHtaData: function() {
      return this.$store.getters.currentData.howToFindUs;
    },
  },
  computed: {
    htaTitle: function() {
      return this.getHtaData().title;
    },
    htaSubtitle: function() {
      return this.getHtaData().subtitle;
    },
  },
  data: function() {
    return {
      contactCards: [
        {
          icon: "fas fa-phone",
          descriptions: ["+34 934 57 47 84"],
          anchorLink: "tel:+34-934-57-47-84"
        },
        {
          icon: "fas fa-map-marker-alt",
          descriptions: [
            "Travessera de Gràcia, 250",
            "Barcelona, Spain",
            "08025",
          ],
          anchorLink:
            "https://www.google.com/maps/place/Tomodachi+-+Restaurant+Japon%C3%A8s/@41.4054657,2.1646083,15z/data=!3m1!5s0x12a4a2bf57a6342d:0x874a6f94d85bff28!4m12!1m6!3m5!1s0x0:0xf921b20935ac0ec4!2sTomodachi+-+Restaurant+Japon%C3%A8s!8m2!3d41.4054657!4d2.1646083!3m4!1s0x0:0xf921b20935ac0ec4!8m2!3d41.4054657!4d2.1646083",
        },
        {
          icon: "fas fa-envelope",
          descriptions: ["tomodachibcn@gmail.com"],
          anchorLink: "mailto:tomodachibcn@gmail.com",
        },
      ],
    };
  },
};
</script>

<style scoped>
.hta-container {
}

#link-contact-us-section {
  margin-top: 10px;
  margin-bottom: 35px;
}
</style>
