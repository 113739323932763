<template>
  <header>
    <nav class="navbar navbar-dark navbar-expand-lg sticky-top">
      <div class="container">
        <a class="navbar-brand text-uppercase font-1" href="#">Tomodachi</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav mr-auto font-3">
            <li v-for="link in links" :key="link.link">
              <a class="nav-link" @click="scrollTo(link.sectionId)">
                {{ link.name }}</a
              >
            </li>
          </ul>
          <ul class="navbar-nav ml-auto border-top font-2">
            <li class="nav-item" :class="language === 'en' ? 'active' : ''">
              <div
                class="nav-link language-button"
                @click="currentLanguage('en')"
              >
                ENG
              </div>
            </li>
            <li class="nav-item" :class="language === 'es' ? 'active' : ''">
              <div
                class="nav-link language-button"
                @click="currentLanguage('es')"
              >
                ESP
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      language: "es",
    };
  },
  computed: {
    links: function () {
      return this.getNavBarItems();
    },
  },
  methods: {
    currentLanguage(lang) {
      this.language = lang;
      this.$store.commit("currentLanguage", lang);
      if (this.$refs.listnav.classList.contains("show"))
        this.$refs.toggler.click();
    },
    getNavBarItems() {
      return this.$store.getters.currentData.navigationItems;
    },
    scrollTo(seciontId) {
      document.querySelector(seciontId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      if (this.$refs.listnav.classList.contains("show"))
        this.$refs.toggler.click();
    },
  },
};
</script>

<style lang="scss">
.language-button {
  cursor: pointer;
}

.nav-link {
  cursor: pointer;
  color: white !important;
}

nav {
  background-color: #383838;
  opacity: 0.9;
}
a.navbar-brand {
  font-size: 22px;
  color: white !important;
}

.scroll-to-top {
  position: fixed;
  color: #fb9a23;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  font-size: 45px;
}

.scroll-to-top:active,
.scroll-to-top:hover {
  color: orangered;
}
</style>
