<template>
  <div id="link-our-location-section" class="text-center container">
    <div class="row">
      <div class="map-container col-lg-6 col-md-12">
        <Map/>
      </div>
      <div class="col-lg-6 col-md-12 contact-form">
        <ContactFormModal/>
      </div>
    </div>
  </div>
</template>

<script>
import ContactFormModal from "./ContactFormModal";
import Map from "./Map";

export default {
  name: "Location",
  components: {
    ContactFormModal,
    Map
  },
  data: function() {
    return {};
  },
  computed: {
    sectionTitle: function() {
      return this.getLocationData().title;
    },
  },
  methods: {
    getLocationData: function() {
      return this.$store.getters.currentData.location;
    },
  },
};
</script>

<style scoped>
#link-our-location-section {
  margin-top: 50px;
}

.map-container {
  padding: 10px;
  /* margin-top: 30px; */
}

.map-container > iframe {
  width: 100%;
  height: 400px;
}

.contact-form {
  /* margin: 3rem 0rem; */
}
</style>
