<template>
    <div class="row">
        <div class="col">
            <p class="module-title font-1 color-4">
            {{ sectionTitle }}
            </p>
        </div>
    </div>
    <div id="link-our-location-section" class="text-center container">
        <div class="row">
        <div class="map-container col-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11970.220698589646!2d2.1646083!3d41.4054657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf921b20935ac0ec4!2sTomodachi%20-%20Restaurant%20Japon%C3%A8s!5e0!3m2!1sen!2ses!4v1639829275718!5m2!1sen!2ses" 
            width="600" 
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy">
            </iframe>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Map",
  data: function() {
    return {};
  },
  computed: {
    sectionTitle: function() {
      return this.getLocationData().title;
    },
  },
  methods: {
    getLocationData: function() {
      return this.$store.getters.currentData.location;
    },
  },
};
</script>

<style scoped>

.map-container {
  padding: 10px;
  /* margin-top: 30px; */
}

.map-container > iframe {
  width: 100%;
  height: 400px;
}

.contact-form {
  /* margin: 3rem 0rem; */
}
</style>
