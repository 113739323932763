<template>
  <div class="contact-card">
    <div class="hta-card font-2">
      <div class="hta-logo">
        <a :href="contactInfo.anchorLink" class="btn btn-lg hta-action">
          <i :class="contactInfo.icon"></i>
        </a>
      </div>
      <p>
        <span v-for="line in contactInfo.descriptions" :key="line">
          {{ line }} <br />
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactCard",
  props: ["contactInfo"],
  data: function () {
    return {};
  },
};
</script>

<style>
@keyframes pulse {
  0% {
    transform: scale(0.98);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.98);
  }
}

/* .contact-card {
  display: flex;
  background-color: white;
  justify-content: center;
  border-radius: 0.5rem;
} */
.btn.hta-action {
  animation: pulse 0.3s linear infinite;
  background-color: white;
  border: solid 1px #fb9a23;
  color: #fb9a23;
}

.btn.hta-action:hover {
  background-color: #fb9a23;
  opacity: 0.8px;
  color: white;
}

.hta-card {
  text-align: center;
  margin-top: 15px;
  font-size: 17px;
}

.hta-logo {
  margin-bottom: 25px;
}
</style>
