<template>
  <div class="container contact-form">
    <div class="contact-form__title font-1 color-4">
      {{ getReservationData.title }}
    </div>

    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#reservationModal"
    >
      <i class="fas fa-book"></i>
      {{ getReservationData.bookATable }}
    </button>
    <!-- Modal -->
    <div
      class="modal fade"
      id="reservationModal"
      tabindex="-1"
      aria-labelledby="reservationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reservationModalLabel">
              {{ getReservationData.title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body menu-link">
            <!-- <ContactForm/> -->
            <iframe
              id="restaurante-tomodachibarcelona"
              title="Reservas"
              :src="`https://reservation.dish.co/widget/hydra-103a5389-9f23-40a0-aaaa-fdcbf02a1ae6`"
              frameborder="0"
              height="550"
              width="100%"
              onload="iFrameResize();"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ContactForm from "./ContactForm";
export default {
  name: "ContactFormModal",
  components: {
    // ContactForm
  },
  computed: {
    iFrameLanguage() {
      return this.$store.getters.currentLanguage === "es"
        ? "spanish"
        : "english";
    },
    getReservationData() {
      return this.$store.getters.currentData.reservations;
    },
  },
};
</script>
<style scoped lang="scss">
// select {
//   width: 100%;
// }
.contact-form {
  padding: 10px;
  &__title {
    font-size: 45px;
    font-family: "Uchen", serif;
    padding: 0px;
    margin-top: 0px;
    word-spacing: -0.3em;
    @media only screen and (max-width: 800px) {
      font-size: 35px;
    }
  }
}

.btn-primary {
  background-color: #003f5c;
  border: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  animation: up-down 1s ease-in-out infinite;

  @media only screen and (min-width: 800px) {
    margin-top: 10rem;
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: #01202e !important;
    transform: scaleX(1.1) scaleY(1.1);
    border-color: none !important;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
