<template>
  <div id="link-about-us-sub-section" class="main-info-section">
    <div class="row text-center pt-5 pb-4">
      <div class="col-12">
        <p class="module-title color-4">{{ infoTitle }}</p>
        <p class="module-subtitle color-1 text-uppercase">{{ infoSubTitle }}</p>
      </div>
    </div>
    <div class="container info-section">
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <p class="info-description font-3">
            {{ infoContent }}
          </p>
        </div>
        <div class="col-md-4 d-none d-md-block">
          <div class="img-1">
            <img
              class="logo"
              src="../../assets/img/tomodachi-name.jpg"
              alt="Tomodachi bcn signature image"
            />
          </div>
          <div class="img-2">
            <img
              class="logo"
              src="../../assets/img/tomodachi-yellow-logo.jpeg"
              alt="Tomodachi bcn icon"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralInfo",
  data: function () {
    return {};
  },
  computed: {
    infoTitle: function () {
      return this.getInfoSection().title;
    },
    infoSubTitle: function () {
      return this.getInfoSection().subtitle;
    },
    infoContent: function () {
      return this.getInfoSection().content;
    },
    generalDescription: function () {
      return this.getDescriptionInfo();
    },
  },
  methods: {
    getInfoSection: function () {
      return this.$store.getters.currentData.infoSection;
    },
  },
};
</script>

<style lang="scss">
.info-section {
}

.logo {
  height: 180px;
}

.btn-outline-secondary.btn-sm {
  color: #173954;
}
.info-description {
  font-size: 20px;
  padding-right: 1rem;
}
.img-1 {
  img {
    border-radius: 10px;
  }
}
.img-2 {
  position: absolute;
  margin-top: -165px;
  margin-left: -30px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: 5px solid #f5f7f5;
  border-radius: 10px;
}
</style>
