<template>
  <section id="link-gallery" class="container">
    <div class="gallery">
      <img
        src="../../assets/img/gallery/11.jpeg"
        alt="Tomodachi bcn sushi chef"
        class="hero-img"
      />
      <img src="../../assets/img/gallery/10.jpeg" alt="Tomodachi bcn sushi delicious 1" class="img1" />
      <img src="../../assets/img/gallery/9.jpeg" alt="Tomodachi bcn sushi delicious 2" class="img2" />
      <img src="../../assets/img/gallery/8.jpg" alt="Tomodachi bcn sushi delicious Sushi Sashimi variados de la casa" class="img8" />
      <img src="../../assets/img/gallery/7.jpg" alt="Tomodachi bcn sushi delicious 4" class="img4" />
      <img src="../../assets/img/gallery/5.jpg" alt="Tomodachi bcn sushi delicious 5" class="img5" />
    </div>
  </section>
</template>


<script>
export default {
  name: "Gallery",
};
</script>

<style scoped lang="scss">
.gallery {
  margin: 3rem 0;
  height: 70vw;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(4, minmax(0, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;

  .hero-img {
    object-position: bottom;
    grid-row: span 2;
    grid-column: span 2;
  }
  .img3,
  .img4,
  .img5 {
    grid-row: span 2;
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 1.5s cubic-bezier(0.13, 1, 0.32, 1);
  &:hover {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 800px) {
  img {
    opacity: 0.9;
    &:hover {
      transform: scale(2.1);
    }
  }
  .gallery {
    margin: 1rem 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 0.4rem;
    row-gap: 0.4rem;
    .hero-img {
      grid-column: span 1;
      &:hover {
        margin-left: 5.7rem;
        margin-top: 4.5rem;
        z-index: 999;
        opacity: 1;
      }
    }
    .img1 {
      &:hover {
        margin-left: -5.7rem;
        margin-top: 2rem;
        z-index: 999;
        opacity: 1;
      }
    }
    .img2 {
      &:hover {
        margin-left: -5.7rem;
        z-index: 999;
        opacity: 1;
      }
    }
    .img3,
    .img5 {
      grid-row: span 1;
      &:hover {
        margin-left: 5.7rem;
        margin-top: -4.5rem;
        z-index: 999;
        opacity: 1;
      }
    }
    .img4 {
      grid-row: span 2;
      &:hover {
        margin-left: -5.7rem;
        margin-top: -4.5rem;
        z-index: 999;
        opacity: 1;
      }
    }
  }
}
</style>
